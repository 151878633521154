<template>
  <main id="box">
    <section class="hero is-ligth is-fullheight-with-navbar">
      <!-- Hero content: will be in the middle -->
      <div class="hero-body">
        <sidebar>
          <transition name="slide-fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </sidebar>
      </div>

      <!-- Hero footer: will stick at the bottom -->
      <div class="hero-foot is-dark">
        <footerbar />
      </div>
    </section>
  </main>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Nav/Navbar.vue'
import Sidebar from '@/components/Side/Sidebar.vue'
import Footerbar from '@/components/Footer/Footerbar.vue'

@Component({
  components: {
    Navbar,
    Sidebar,
    Footerbar,
  },
})
export default class BoxLayout extends Vue {}
</script>

<style lang="scss">
#box {
  .hero-body {
    padding: 0rem !important;
  }

  .hero.is-halfheight .hero-body,
  .hero.is-fullheight .hero-body,
  .hero.is-fullheight-with-navbar .hero-body {
    align-items: inherit;
    display: flex;
  }
  .hero-foot {
    background-color: #f4f5f7;
  }
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
